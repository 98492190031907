<template>
  <div>
    <div class="p-mb-3">
      <Button v-if="buttons.xztmBt" :disabled="mainId?null:'disabled'" @click="addOrUpdate({})">新增条目</Button>
    </div>
    <DataTable :value="tableData" :row-hover="true">
      <template #empty>
        无数据。
      </template>
      <Column field="itemKey" header="字典项名称"></Column>
      <Column field="itemValue" header="字典项值"></Column>
      <Column field="lang" header="语言"></Column>
      <Column field="comments" header="备注"></Column>
      <Column header="操作">
        <template #body="row">
          <Button v-if="buttons.tmbjBt" class="p-button-text" @click="addOrUpdate(row.data)">编辑</Button>
          <Button v-if="buttons.tmscBt" class="p-button-text" @click="deleteItem(row.data)">删除</Button>
        </template>
      </Column>
    </DataTable>
    <Paginator :rows="page.size" :totalRecords="page.total"
               @page="changePage"
               template=" PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown CurrentPageReport"
               :rowsPerPageOptions="[10,20,50,100]"
               currentPageReportTemplate="共{totalRecords} 条记录"
    >
      <template #right>
        <label>跳至&nbsp;&nbsp;</label>
        <InputNumber v-model="page.current" @keydown.enter="getList"  v-tooltip.focus="'按下回车键后跳转'" inputStyle="width: 40px;"/>
        <label>&nbsp;&nbsp;页</label>
      </template>
    </Paginator>
    <add-or-update-dict-item v-if="addOrUpdateDisplay" ref="aou" @close="closeDialog"></add-or-update-dict-item>
  </div>
</template>

<script>
import AddOrUpdateDictItem from "@/views/modules/dataDictionary/addOrUpdateDictItem";

export default {
  name: "dictItemTable",
  components: {AddOrUpdateDictItem},
  props: ['buttons'],
  data() {
    return {
      tableData: null,
      mainId: null,
      page: {
        size: 10,
        total: 0,
        current: 1
      },
      addOrUpdateDisplay: false
    }
  },
  methods: {
    init(id) {
      this.display = true
      this.mainId = id
      this.getList()
    },
    treeNodeChange() {
      this.tableData = null
      this.page = {
        size: 10,
        total: 0,
        current: 1
      }
      this.mainId = null
    },
    getList() {
      this.$http('/dictItem/getListByMainId', 'get', {
        size: this.page.size,
        current: this.page.current,
        id: this.mainId
      }, (res) => {
        this.tableData = res.data.records
        this.page.total = res.data.total
      })
    },
    changePage(page) {
      this.page.size = page.rows
      this.page.current = page.page + 1
      this.getList()
    },
    addOrUpdate(data) {
      if(this.mainId) {
        let d = JSON.parse(JSON.stringify(data))
        d.dictId = this.mainId
        this.addOrUpdateDisplay = true
        this.$nextTick(() => {
          this.$refs.aou.init(d)
        })
      }
    },
    closeDialog() {
      this.addOrUpdateDisplay = false
      this.getList()
    },
    deleteItem(data) {
      this.$confirm.require({
        header: '删除',
        message: '您确定要删除"' + data.itemKey + '"吗',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: '确认',
        rejectLabel: '取消',
        accept: () => {
          this.$http("/dictItem/delete/" + data.id, "delete", null, () => {
            this.$toast.add({severity: 'success', summary: '成功', detail: '删除成功', life: 3000});
            this.$confirm.close()
            this.getList()
          })
        },
        reject: () => {
          this.$confirm.close()
        }
      });
    }

  }
}
</script>

<style scoped>

::v-deep(.p-datatable .p-datatable-tbody > tr > td) {
  white-space: nowrap;
  text-align: center;
}

::v-deep(.p-datatable .p-column-header-content) {
  white-space: nowrap;
  justify-content: center;
  text-align: center;
}
</style>
